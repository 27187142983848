<template>
    <v-row no-gutters>      
            <v-col cols="5" md="4"  class="ml-3 mb-2 mt-2">
                      <v-autocomplete
                        :rules="[(v) => !!v || 'required']"
                        v-model="customerDto"
                        item-text="customerName"
                        :items="customerList"
                        label="Customer"
                        name="customerName"
                        return-object
                        required
                        dense
                        outlined
                        filled
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" md="2"  class="ml-3 mb-2 mt-2">
                      <v-autocomplete
                        :rules="[(v) => !!v || 'required']"
                        v-model="status"
                        item-text="name"
                        :items="statusList"
                        label="Status"
                        name="name"
                        return-object
                        required
                        dense
                        outlined
                        filled
                      >
                    </v-autocomplete>
                  </v-col>
              <v-col cols="1" md="1" class="pl-2 pt-3">
              <v-btn icon class="primary" @click="invoiceListMethod()" small>
                <v-icon color="white"> search </v-icon>
              </v-btn>
            </v-col>
            <!-- <v-col cols="5" md="3" class="pt-1">
              <v-alert dense border="left" colored-border color="primary accent-4">
                Balance - {{ totalAmt | numberFormat }}
              </v-alert>
            </v-col> -->
             <!-- <v-btn icon class="primary" @click="save()" small>
                <v-icon color="white"> save </v-icon>
              </v-btn> -->
            <v-col cols="12" md="12" class="pl-2 pr-2">
              <v-simple-table
               :fixed-header="true"
               class="elevation-1 align-center"
               height="83vh"
               dense
             >
               <template v-slot:default>
                 <thead>
                   <tr>
                     <th class="text-center white--text primary">No.</th>
                     <!-- <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">check</th> -->
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">ReceivedDate</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">invoiceNo</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Customer Name</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Sign</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Total Amt</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary"> Payment</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Balance</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Remark</th>
                     <th class="text-center white--text primary">
                       <v-divider vertical></v-divider>
                     </th>
                     <th class="text-center white--text primary">Action</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr
                     v-for="(item, index) in invoiceList"
                     :key="index"
                     @click="selectedOne = item"
                     :style="{
                       backgroundColor:
                         item.invoiceId == selectedOne.invoiceId
                           ? '#def3ff'
                           : 'transparent',
                     }"
                   >
                     <td class="text-center">{{ index + 1 }}</td>                 
                     <!-- <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-center"><v-checkbox  :value="item.invoiceId" 
                     id="item.invoiceId"    @change="check(item)" dense></v-checkbox></td> -->
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-center">{{ item.receivedDate }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-center">{{ item.invoiceCode }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td>{{ item.customer.customerName }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-right" >{{ item.exchange.symbols }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-right" >{{ item.totalAmount|numberFormat }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-right" >{{ item.otherPayment|numberFormat }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-right" >{{ item.balance|numberFormat }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-right" >{{ item.remark }}</td>
                     <td class="text-center"><v-divider vertical></v-divider></td>
                     <td class="text-center">
                      <v-btn small icon color="black" @click="clickInvoicePrint(item)" >
                         <v-icon>print</v-icon></v-btn
                       >
                      <v-btn small icon color="primary" @click="clickPartialPayment(item)" class="ml-3">
                         <v-icon>save</v-icon></v-btn
                       ><v-btn small icon color="blue" @click="clickDetailDialog(item)" class="ml-3">
                         <v-icon>info</v-icon></v-btn
                       >
                     </td>
                   </tr>
                   <v-divider />
                 </tbody>
               </template>
             </v-simple-table>
            </v-col>
        <v-col>
          <v-dialog
          v-model="dialogSave"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 white--text primary">
              Expenses payment for ({{invoiceDto.invoiceCode}} ,balance {{symbolSign}}{{invoiceDto.balance}}) 
            </v-card-title>
            <v-card-text class="text-h6 mt-5">
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <v-autocomplete
                          v-model="exchangeDto"
                          item-text="symbols"
                          :items="exchangeList"
                          label="Currency Sign"
                          name="symbols"
                          return-object
                          required
                          dense
                          outlined
                          filled
                        >
                        </v-autocomplete>
                </v-col>
                <v-col cols="12" md="8" class="pl-2">
                  <v-autocomplete
                        :rules="[(v) => !!v || 'required']"
                        v-model="cashtype"
                        item-text="typeName"
                        :items="cashtypeList"
                        label="Cash Type"
                        name="typeName"
                        return-object
                        required
                        dense
                        outlined
                        filled
                      >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="noteDto"
                          item-text="note"
                          :items="noteList"
                          label="Product Code"
                          name="note"
                          return-object
                          required
                          dense
                          outlined
                          filled
                        >
                        </v-autocomplete>
                      </v-col>
                  <v-col cols="12" md="6" class="pl-2">
                    <v-text-field v-model="noteremark" label="Remark"  filled outlined dense></v-text-field>
                  </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.number="cash" label="Cash"   type="number" filled outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.number="exRate" label="Exchange Rate" class="pl-2"  type="number" filled outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model.number="bal" label="Balance"   type="number" filled outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="paymentRemark" label="Remark" class="pl-2" filled outlined dense></v-text-field>
                </v-col>
              </v-row>
                    
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogSave = false"
              >
                CANCEL
              </v-btn>
              <v-btn
              dark
                color="primary"
                text
                @click="clickSaveDialog()"
              >
                SAVE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-col>
        <v-col>
              <v-bottom-sheet v-model="dialogDetail" fullscreen scrollable>
            <v-sheet>
              <invoicePaymentHistory
                @closeDialog="dialogDetail=false"
                :invPaymentDtl="invPaymentDtl"
              />
            </v-sheet>
          </v-bottom-sheet>
            </v-col>
            </v-row>
    </template>
    <script>
    import invoiceService from "../service/InvoiceService";
    import customerService from "../service/CustomerService";
    import billService from "../service/BillService";
    import invoicePaymentHistory from "@/components/InvoicePaymentHistory.vue";
    import exchangeService from "../service/ExchangeService";
    import noteService from "../service/NoteService";
    export default {
      data: () => ({
        invPaymentDtl:{},
        dialogDetail:false,
        invoiceList:[],
        invoiceDto:{},
        selectedOne:{},
        deleteInvoice:{},
        customerList:[],
        customerDto:{},
        chk:false,
        chkList:[],
        totalAmt:0,
        dialogSave:false,
        cash:0,
        bal:0,
        exRate:1,
        invPaymentDto:{},
        cashtypeList: [],
        cashtype: {},
        paymentRemark:"",
        invPaymentList:[],
        symbolSign:"",
        iscash:false,
        exchangeList:[],
        exchangeDto:{},
        statusList: ["ACTIVE", "INACTIVE"],
        status: "",
        noteList:[],
        noteDto:{},
        noteremark:"",
      }),
       props: {},
      mounted: function() {
        this.customerListMethod();
        this.getCashListMethod();
        this.exchangeListMethod();
        this.noteListMethod();
        this.status = this.statusList[0];
      },
      methods: {
        noteListMethod: function () {
          noteService
            .getNoteList()
            .then((response) => {
              this.noteList.splice(0, this.noteList.length);
              this.noteList.push(...response);
              this.noteDto = this.noteList[0];
            })
            .catch((error) => {
              this.$swal("Fail!", error.response.data.message, "error");
            });
        },
        clickInvoicePrint:function(item){
          let invoiceId = item.invoiceId;
              let query = {invoiceId};
                this.$router.push({
                  name: "invpayprint",
                  query,
                }) .catch(() => {});
        },
         clickDetailDialog:function(item){
              this.invPaymentDtl = item;
              this.dialogDetail = true;      
            },   
        clickSaveDialog:function(){
          if(this.cash>0 && this.cash<=this.invoiceDto.balance){
            this.invPaymentList.splice(0);
          this.invoiceDto.advancePaid=this.invoiceDto.advancePaid;
          this.invoiceDto.balance=this.invoiceDto.balance-this.cash;
          this.invPaymentDto.typeId=this.cashtype.typeId;
          this.invPaymentDto.payment=this.cash;
          this.invPaymentDto.exchangeRate=this.exRate;
          this.invPaymentDto.balance=this.bal;
          this.invPaymentDto.paymentMMK=this.bal;
          this.invPaymentDto.symbols=this.exchangeDto.symbols;
          this.invPaymentDto.remark=this.paymentRemark;
          this.invPaymentDto.note=this.noteDto.note;
          this.invPaymentDto.noteRemark=this.noteremark;
          this.invoiceDto.invPayment=this.invPaymentDto;
          this.invPaymentList.push(this.invPaymentDto);
          this.invoiceDto.invPayment=this.invPaymentList;
          invoiceService
            .updateInvoicePayment(this.invoiceDto)
            .then((response) => {
              this.dialogSave=false;
              this.invoiceListMethod();
              this.noteListMethod();
                this.cash = 0;
                this.paymentRemark = "";
                this.bal=0;
                this.exRate=1;
                this.$swal({
                    title: "Successful",
                    text: "Save,Success!",
                    icon: "success",
                    timer: 500,
                  });
              })
              .catch((error) => {
                this.$swal("Fail!", error.response.data.message, "error");
              });
          }else{
             this.$swal("Warning!", "Cash amount should be greater than 0 or less than or equal the remaining bal!", "warning");
          }
        },
        clickPartialPayment:function(item){
            this.dialogSave=true;
            this.invoiceDto = Object.assign({},item);
            this.symbolSign=this.invoiceDto.exchange.symbols;
            this.exchangeDto=this.invoiceDto.exchange.symbols;
        },
        exchangeListMethod: function () {
          exchangeService
            .getExchange()
            .then((response) => {
              this.exchangeList.splice(0, this.exchangeList.length);
              this.exchangeList.push(...response);
              this.exchangeDto = this.exchangeList[0];
            })
            .catch((error) => {
              this.$swal("Fail!", error.response.data.message, "error");
            });
        },
               
        customerListMethod: function () {
          customerService
            .getCustomer()
            .then((response) => {
              this.customerList.splice(0);
              this.customerList.push(...response);
              this.customerDto = this.customerList[0];
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        },
        getCashListMethod: function () {
          billService
            .getCashTypes()
            .then((response) => {
              this.cashtypeList.splice(0, this.cashtypeList.length);
              this.cashtypeList.push(...response);
              this.cashtype = this.cashtypeList[0];
            })
            .catch((error) => {
              this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
            });
        },
        invoiceListMethod: function(){
          invoiceService
              .getInvoiceListByCustomer(this.customerDto.customerId,this.status,'EXPENSE')
              .then((response) => {
                this.invoiceList.splice(0);
                this.invoiceList.push(...response);
              })
              .catch((error) => {
                this.$swal("Fail!", error.response.data.message, "error");
              });
        },
      },
      watch: {
        cashtype(){
            this.exRate=this.cashtype.unitAmount;
          },
        noteDto(){
          this.noteremark=this.noteDto.remark;
        },
        cash(){
          this.iscash=true;
          this.bal = (Number(this.cash)*Number(this.exRate));
        },
        exRate(){
          this.bal = (Number(this.cash)*Number(this.exRate));       
        },
      },
      components: {invoicePaymentHistory}
    };
    </script>
    <style scoped>
    .v-data-table td,
    .v-data-table th {
      padding: 0 2px !important;
    }
    .login-full {
      height: 99vh;
    }
    </style>